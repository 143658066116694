export const locale = {
  GENERAL: {
    LANG: 'EN',
    NAV_OPTIONS: ['Home', 'About', 'Jobs', 'Benefits', 'Contact', 'Location'],
    NAV_ANCHOR_OPTIONS: ['#home-anchor', '#about-anchor', '#jobs-anchor', '#benefits-anchor', '#contact-anchor', '#information-anchor']
  },
  INTRO: {
    TITLE: 'Sonic Star Global Limited Taiwan Branch',
    SUBTITLE: ['Integrity', 'Result Driven', 'Trust with Transparency', 'Move Across the Boundary', 'Listen in and Speak out'],
    JOIN_US: 'Join Us'
  },
  ABOUT: {
    CONTENT: 'Sonic Star Global Ltd. (Sonic Star) is a professional silicon intellectual property (IP) provider, with its principal office located in Hsinchu, Taiwan. Our strength is in R&D and customer service. We focus on providing high-speed interface IPs, mixed-mode circuit IPs, standard cell library solutions, and multimedia related HW/SW solutions. Sonic Star\'s IPs are targeted at advanced process technologies, providing customers with differentiated IP solutions. These solutions help customers grasp market opportunities in short design cycles, low manufacturing cost, and high product competitiveness.',
    TW_CONTENT: '',
    LEARN_MORE: 'Learn more'
  },
  JOBS: {
    TITLE_OPTIONS: ['Mixed-Signal/Digital Design Expert', 'Analog IP Design Expert', 'Multimedia and AI HW/SW Design Expert', 'More Information'],
    SUBTITLE_OPTIONS: ['Charge Pump, LDO, Buck/Boost, AMOLED Driver, SERDES, Audio Driver, Audio CODEC, Clocking', 'High speed DLL, PLL, DDRIO, High speed PHY, ADC, DAC', '3A, ISP, Display, Algorithm, AI, RTL, FW', 'More Information']
  },
  BENIFITS: {
    TITLE_OPTIONS: ['Competitive Total Package', 'Insurance Plan', 'Flexible Vocation Policy', 'Health Care Plan', 'Delicious snacks and drinks', 'Relaxation activities'],
    SUBTITLE_OPTIONS: ['Competitive fixed pay and variable bonus', 'In addition to the statutory insurance, the group insurance program is included', 'Additional floating holidays and annual leave', 'Periodical health examination program', 'Birthday celebration, free snacks, non-alcoholic beverage and monthly afternoon tea are provided', 'Domestic travel, family day, year-end party, company anniversary celebration, club activities, etc.'],
    IMG_NAME_OPTIONS: ['#dollar', '#insurance', '#holiday', '#health', '#cup', '#plane']
  },
  CONTACT: {
    NAME: 'Your Name (required)',
    EMAIL: 'Your Email (required)',
    SUBJECT: 'Subject',
    MESSAGE: 'Message',
    SUBMIT: 'Submit'
  },
  INFO: {
    TITLE: ['Address', 'Support Info'],
    COMPANY_NAME: 'Sonic Star Global Limited Taiwan Branch',
    COMPANY_ADDRESS: '10F.-2, No. 168, Sec. 2, Fuxing 3rd Rd., Zhubei City, Hsinchu County 302'
  }
};
