export const locale = {
  GENERAL: {
    LANG: 'CHT',
    NAV_OPTIONS: ['首頁', '關於我們', '工作機會', '員工福利', '聯絡我們', '位置資訊'],
    NAV_ANCHOR_OPTIONS: ['#home-anchor', '#about-anchor', '#jobs-anchor', '#benefits-anchor', '#contact-anchor', '#information-anchor']
  },
  INTRO: {
    TITLE: '英屬維京群島商爍星有限公司台灣分公司',
    SUBTITLE: ['誠信正直', '目標導向', '信任透明', '突破框架', '有效溝通'],
    JOIN_US: '加入我們'
  },
  ABOUT: {
    CONTENT:
      '英屬維京群島商爍星有限公司台灣分公司是個嶄新、年輕的新創公司，致力於為消費性電子產品提供各種類比、數位、混合信號、多媒體相關軟硬體解決方案。我們的優勢在於研發和客戶服務，為客戶定制具有市場競爭力的創新產品。',
    TW_CONTENT: '歡迎加入爍星的行列。您將有機會與業界精英共同合作、挑戰極限、再創高峰。',
    LEARN_MORE: '了解更多'
  },
  JOBS: {
    TITLE_OPTIONS: ['混合信號/數位設計專家', '類比IP設計專家', '多媒體與AI軟體/硬體設計專家', '更多工作資訊'],
    SUBTITLE_OPTIONS: ['Charge Pump, LDO, AC/DC, DC/DC, Buck/Boost, AMOLED Driver, SERDES, Audio Driver, Audio CODEC, Clocking', 'High speed DLL, PLL, DDRIO, High speed PHY, ADC, DAC', '3A, ISP, Display, Algorithm, AI, RTL, FW', 'More Information']
  },
  BENIFITS: {
    TITLE_OPTIONS: ['優渥的薪酬', '完備的員工保險', '彈性的休假政策', '身體健康的照護', '美味豐盛的點心與飲料', '多元的活動'],
    SUBTITLE_OPTIONS: ['優於同業的薪資水準以及工作獎金', '除勞保、健保、及勞工退休金提撥外，還為員工加保團體保險', '優於法令的休假制度：彈性休假、特別休假', '定期性的全面健康檢查守護你的健康', '慶生會、零食飲料定期補給、不定期聚餐及下午茶供應…等，滿足你的味蕾', '國內員工旅遊、家庭日、尾牙、公司創立周年慶祝會…等 '],
    IMG_NAME_OPTIONS: ['#dollar', '#insurance', '#holiday', '#health', '#cup', '#plane']
  },
  CONTACT: {
    NAME: '您的姓名 (必填)',
    EMAIL: '您的電子郵件 (必填)',
    SUBJECT: '主題',
    MESSAGE: '訊息',
    SUBMIT: '送出訊息'
  },
  INFO: {
    TITLE: ['聯絡地址', '聯絡資訊'],
    COMPANY_NAME: '英屬維京群島商爍星有限公司台灣分公司',
    COMPANY_ADDRESS: '新竹縣竹北市復興三路2段168號10樓之2'
  }
};
